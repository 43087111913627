import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import CardHeader from '@material-ui/core/CardHeader';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PageHeader from '../components/templateHeader';
import { makeStyles } from '@material-ui/core/styles';

import Canvas from '../components/canvas';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { Alert, AlertTitle } from '@material-ui/lab'; 
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import AlbumTwoToneIcon from '@material-ui/icons/AlbumTwoTone';
import { getLocalValue, setLocalValue,retriveInformation } from '../lib/utils';
import Divider from '@material-ui/core/Divider';
import SendSharpIcon from '@material-ui/icons/SendSharp';
const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 140,
  },
});
var correctAnswer = -1; 
var userAnswer = "-";
var questionString = "";
var openSnack=true;
var toastType="";
var toastMessage="Correct!";
var previousQuestionType = "";
var totalAttempted = 0;
var totalCorrect = 0;
var isCorrect=false;
var vertical='top';
var horizontal = 'center';
var errorMessage = "";
var qType = "";
var maxQuestion = 0;
var messageImage= "good1.png";
/*
Content-Type:application/json
Origin:http://localhost:8000
*/
function calculatePreviousCorrectAnswers(previousAnswers){
  var correctedCount = 0;
  if(previousAnswers==null || typeof previousAnswers==="undefined"){
    return correctedCount;
  }
  for(var len=0;len<previousAnswers.length;len++){
    correctedCount += (previousAnswers[len].isCorrect? 1 : 0);
  }
  return correctedCount;
}
function postData(isAddingNote){

  if(!getLocalValue('sid')){
    return;
  }
  var messageBody = {
    "actionName" : "sheetProcessing",
    "answer": userAnswer,
    "isCorrect": isCorrect,
    "question_text": questionString,
    "exercise_id": getLocalValue(qType+"exerciseId"),
    "register":(totalAttempted==1?true:false),
    "question_type" : qType,
    "finished":(totalAttempted==maxQuestion?true:false),
    "maxQuestion": maxQuestion
  };

  if(isAddingNote){
    if(!document.getElementById("postexcercisenote").value){
      return;
    }
    messageBody = {
      "actionName" : "sheetProcessing",
      "exercise_id": getLocalValue(qType+"exerciseId"),
      "note" : document.getElementById("postexcercisenote").value.trim(0,255)
    };
  }

  retriveInformation(
    process.env.AWS_POST_ANSWERS,
    "POST",{},
    messageBody,
    function(){}
  );

  /*
  fetch(process.env.AWS_POST_ANSWERS, {
      mode: 'cors',
      method: 'POST',
      headers: {'Content-Type':'application/json',"authorization":getLocalValue('sid')},
      body: JSON.stringify(messageBody)
    }).then(function(response) {
      console.log(response);
      if(response.status!=200){
        window.location=process.env.AWS_LOGIN_URL;
      }
      if (!response.ok) {
        //throw Error(response.statusText);
      }
      // Read the response as json.
      return response.json();
    })
    .then(function(responseAsJson) {
      // Do stuff with the JSON
      //console.log(responseAsJson);
    })
    .catch(function(error) {
      console.log('Looks like there was a problem: \n', error);
    });
  */
}
function changeQuesitonValue(state,questionType){
  qType = questionType;
  maxQuestion = state.maxQuestions;
  //console.log("inside the method");
  const menuItems = ['Addition', 'Substraction', 'Multiplication', 'Division'];
  var number1 = Math.floor((Math.random() * 100) + 1);
  var number2 = Math.floor((Math.random() * 100) + 1);

  if(questionType===menuItems[0]){
    state["questionString"] = number1+" + "+number2;
    correctAnswer = number1+number2;
  }
  else if(questionType===menuItems[1]){
    if(number1<number2){
      var  temp = number1;
      number1 = number2;
      number2 = temp;
    }
    state["questionString"] =  number1+" - "+number2;
    correctAnswer = number1-number2;
  }    
  else if(questionType===menuItems[2]){
    state["questionString"] =  number1+" × "+number2;
    correctAnswer = number1*number2;
  }
  else if(questionType===menuItems[3]){
    number1 = number2 * Math.floor((Math.random() * 10) + 1);
    state["questionString"] =  number1+" ÷ "+number2;
    correctAnswer = number1/number2;
  } 
  else{
    state["questionString"] = "What's your age?";
    correctAnswer=-1;
  }   
  questionString = state["questionString"];
  //console.log(state); 

  if(typeof document != "undefined" && document.getElementById("user_answer")){
    document.getElementById("user_answer").focus();
  }
  return correctAnswer;
}

function saveAnswer(){
  console.log(userAnswer+"==="+ correctAnswer+"---"+questionString);
  if(userAnswer==correctAnswer){
    totalCorrect++;
    return true;
  }
  return false;
}
function getSaveSheetProgress(excerciseCategory){
  if(typeof localStorage !== 'undefined'){
      var storedValue = localStorage.getItem('excerciseCategory'+excerciseCategory);
      if(storedValue==="" || storedValue=== null || typeof storedValue === 'undefined'){
           return [];   
      }   
      else{
          return JSON.parse(storedValue);
      }
  }
  return [];
}

function saveSheetProgress(excerciseCategory,soFarProgress){
  if(typeof localStorage !== 'undefined'){
      localStorage.setItem('excerciseCategory'+excerciseCategory, soFarProgress);
  }
}
function removeSaveSheetProgress(excerciseCategory){
  if(typeof localStorage !== 'undefined'){
    localStorage.removeItem('excerciseCategory'+excerciseCategory);
  }
}

const Headline = ({  questionType, onAnswerOperation,questionsPerExcercise }) => {
//var maxQuestions = parseInt( questionsPerExcercise );

setLocalValue("lastExcerciseName",questionType);
const [state, setState] = React.useState({
        "questionString": "",
        selectedIdx : 0,
        "hasError" : false,
        "isFinished" : {questionType : "false"},
        "maxQuestions" : ""+questionsPerExcercise,
        "noteSent" : "visible"
});  
const [maxQuestions, setMaxQuestions] = React.useState('false');
const [isFinished, setIsFinished] = React.useState({questionType : "false"});
const [questionString, setQuestionString] = React.useState(''); 
const [hasError, setHasError] = React.useState(''); 
const [noteSent, setNoteSent] = React.useState(''); 
if(!getLocalValue('sid')){
  state.noteSent="none";
}
const classes = useStyles();
const [open, setOpen] = React.useState(false);
const handleClick = () => {
  setOpen(true);
};
const changeQuestion= (event) =>{
     if(userAnswer=="-" || userAnswer==""){
          setHasError(true);
          state["hasError"]=true;
          errorMessage = "Incorrect Entry";
      }
      else{
            setHasError(false);
            state["hasError"]=false;
            messageImage =  "/static/img/good"+(Math.floor(Math.random() * 5))+".png";
            errorMessage = "";    
            isCorrect = saveAnswer();
            postData();
            if(isCorrect){
              setOpen(true);
              toastType = "success";
              toastMessage = "Correct!";
            }
            else{
              setOpen(true);
              toastType = "warning";
              toastMessage = "Sorry, that's not correct answer";
              messageImage =  "/static/img/opps"+(Math.floor(Math.random() * 2))+".png";
            }
            //alert("changeQuestion");
            if(document)
            document.getElementById("user_answer").value = "";
            userAnswer="-";
            var d = new Date();
            var n = d.getTime();
            
            onAnswerOperation(
              {
                "sqNum" : n,
                "isCorrect" : isCorrect,
                "excerciseId" : "ex",
                "excerciseCategory" : questionType,
                "totalAttempted" : totalAttempted,
                "question_text" : questionString,
                "userAnswer" : userAnswer,
                "isRestarted" : "false"
              }
            );
            if(state.maxQuestions<=getSaveSheetProgress(questionType).length+1){
              //alert('here 1');
              state.isFinished[questionType] = "true";
              setIsFinished(state.isFinished);
              
            }
            else if( state.maxQuestions>getSaveSheetProgress(questionType).length-1 ){    
              totalAttempted++;
              changeQuesitonValue(state,questionType);
              setQuestionString(state["questionString"]);
            }

    }
};

const captureAnswer= (event) =>{
  userAnswer = event.target.value;
};
if(typeof state.isFinished[questionType]==="undefined"){
  state.isFinished[questionType]="false";
}
if(state.isFinished[questionType] === "false" && (state.questionString=="" || previousQuestionType != questionType)){
  totalAttempted = 0;
  totalAttempted = getSaveSheetProgress(questionType).length+1;
  changeQuesitonValue(state,questionType);
  previousQuestionType = questionType;
  totalCorrect = calculatePreviousCorrectAnswers(getSaveSheetProgress(questionType));
  if(totalAttempted==0){
    totalAttempted =1;
  }
  totalCorrect = calculatePreviousCorrectAnswers(getSaveSheetProgress(questionType));

  if(typeof document !== 'undefined' && document.getElementById("user_answer")!= null){
    document.getElementById("user_answer").value = "";
  }
  if(totalAttempted==1){
    var d = new Date();
    var n = d.getTime();
    setLocalValue(questionType+"exerciseId",n);
    state.isFinished[questionType] = "false";
    setIsFinished(state.isFinished);
  }
  else if(totalAttempted>=maxQuestion){
    //alert("here 2---"+totalAttempted+"--"+maxQuestion);
    state.isFinished[questionType] = "true";
    setIsFinished(state.isFinished);
  }

}

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpen(false);
};
const startNewExcercise = (event) =>{
  if(state.isFinished[questionType]==="true"){
    removeSaveSheetProgress(questionType);
    state.isFinished[questionType] = "false";
    setIsFinished(state.isFinished);
    totalAttempted =1;
    var d = new Date();
    var n = d.getTime();
    setLocalValue(qType+"exerciseId",n);
    onAnswerOperation(
      {
        "sqNum" : "xxxx",
        "isCorrect" : isCorrect,
        "excerciseId" : "ex",
        "excerciseCategory" : questionType,
        "totalAttempted" : totalAttempted,
        "question_text" : questionString,
        "userAnswer" : userAnswer,
        "isRestarted" : "true"
      }
    );
    state.noteSent = "visible";
  }
  //changeQuestion();
};
function showToastMessage(type,msg){
              setOpen(true);
              toastType = type;
              toastMessage = msg;
}
var sendANoteErrorMessage = "Note will be sent to co-ordinator for review.";
const addNote = (event) =>{
   if(!getLocalValue('sid')){
    showToastMessage("error","you are not signed in");
   }
   else if(document && !document.getElementById("postexcercisenote").value){
    state.hasError = true;
    setHasError(true);
    document.getElementById("postexcercisenote").focus();
   }
   else{
    state.hasError = false;
    setHasError(false);
    state.noteSent = "none";
    setNoteSent("none");
    if(document)
    document.getElementById("sendanotebox").display = "none";
    postData(true);
    showToastMessage("success","Thank you for adding note.");
   }
   
};
return (
  <Container className={classes.root}>
    
  <Card  >
    <CardHeader subheader={state.isFinished[questionType]==="false"?"( "+totalAttempted + " / " +state.maxQuestions+" )" 
    + (totalAttempted==1?"":" - correct - "+totalCorrect+" ("+((parseInt(totalCorrect)/parseInt(totalAttempted-1))*100).toFixed(0)+"%)") :""} 
    title={questionType}>
    </CardHeader>
            { state.isFinished[questionType]==="true"  ?
            <CardContent>
              <Box>
              <Alert severity="success"><AlertTitle>Completed</AlertTitle>
              You have completed this excercise with {((parseInt(totalCorrect)/parseInt(totalAttempted-1))*100).toFixed(0)+"%"} score. You can start new excercise by clicking below. 
              <br/><br/>
              <Box id="sendanotebox" display={state.noteSent} style={{marginBottom  :"10px"}}>
                  <TextField variant="filled"  id="postexcercisenote" label="Send a Note"  error={state["hasError"]}   
                  multiline style={{color : "red"}}
                  rows={2}
                  helperText={sendANoteErrorMessage}
                  />
                  <Button  color="primary" onClick={addNote}><SendSharpIcon /></Button>
              </Box> </Alert>      
              </Box>
              
              <Divider />
                  <Box style={{marginTop  :"10px"}}>
                    <Button variant="contained" color="primary" onClick={(event) => startNewExcercise(event) }>
                      Start New Excercise
                    </Button>
                  </Box>
                </CardContent>  
            :    
            <CardContent>
                    {state["questionString"]}&nbsp;=&nbsp;<br/> 
                    <Box width={1} component="div" > 
                    <TextField id="user_answer" name="user_answer" label="Answer" autoFocus={true} variant="filled" 
                    onChange={(event) => captureAnswer(event) } type="number" error={state["hasError"]} 
                    helperText={errorMessage} onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        changeQuestion(ev);
                        ev.preventDefault();
                      }
                    }}
                    />
                    </Box>
                    <Box width={1} component="div" margin={2} >
                    <Button size="large" variant="contained" color="primary"  onClick={(event) => changeQuestion(event) }>
                      Next
                    </Button>
                    </Box>
          </CardContent>
          }
          <CardActions>
          </CardActions>
    </Card>
    <div id="snackArea">
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={toastType}>
                {toastMessage}
              </Alert>
            </Snackbar>
    </div>
    </Container>

  );
}

export default Headline;

