import React from 'react';
import ReactDOM from 'react-dom';
import CardHeader from '@material-ui/core/CardHeader';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PageHeader from '../components/templateHeader';
import { makeStyles } from '@material-ui/core/styles';

import Canvas from '../components/canvas';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { Alert, AlertTitle } from '@material-ui/lab'; 
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AvTimerTwoToneIcon from '@material-ui/icons/AvTimerTwoTone';
import Popover from '@material-ui/core/Popover';
import Slider from '@material-ui/core/Slider';
import { getLocalValue, setLocalValue,sliceArray } from '../lib/utils';
import { Bar } from 'react-chartjs-2';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      typography: {
        padding: theme.spacing(2),
      },
    orange: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
    },
    purple: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
    },
  }));

  var defaultValue = 25;
  try{
            defaultValue = parseInt( getLocalValue("questionsPerExcercise")==null? "25" : getLocalValue("questionsPerExcercise") );

  }catch(err){
      alert(err);
  }

  const Settings = ({  addNext, onChangeReflectInApp }) => {
    defaultValue = parseInt( getLocalValue("questionsPerExcercise")==null? "25" : getLocalValue("questionsPerExcercise") );  
    const classes = useStyles();
    const [trackerMap, setTrackerMap] = React.useState('');  
    const [state, setState] = React.useState({
            "trackerMap":  ""
     }); 

    const handleClick = (event) => {

    };
    function valuetext(value) {
        setLocalValue("questionsPerExcercise",value);
        if(defaultValue > value){
                setLocalValue(
                    "excerciseCategoryMultiplication",
                    []//JSON.stringify(sliceArray(JSON.parse(getLocalValue("excerciseCategoryMultiplication")),value))
                );
                setLocalValue(
                    "excerciseCategoryDivision",
                    []//JSON.stringify(sliceArray(JSON.parse(getLocalValue("excerciseCategoryDivision")),value))
                );      
                setLocalValue(
                    "excerciseCategorySubstraction",
                    []//JSON.stringify(sliceArray(JSON.parse(getLocalValue("excerciseCategorySubstraction")),value))
                );      
                setLocalValue(
                    "excerciseCategoryAddition",
                    []//JSON.stringify(sliceArray(JSON.parse(getLocalValue("excerciseCategoryAddition")),value))
                );       
        }       
        onChangeReflectInApp(value);
        return value;
    }

      //   https://www.educative.io/edpresso/how-to-use-chartjs-to-create-charts-in-react
    return (
                <Container>
                            <div className={classes.root}>
                            <h2>Settings</h2>
                            <Paper style={{padding :"10px"}}>
                            <Typography id="discrete-slider-small-steps" gutterBottom>
                                Number of questions in each excercise - {getLocalValue("questionsPerExcercise")}
                            </Typography>
                            <Slider
                                defaultValue={defaultValue}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider-small-steps"
                                step={5}
                                marks
                                min={5}
                                max={100}
                                valueLabelDisplay="auto"
                            />
                            </Paper>
                            </div>
                </Container>
    );


}

export default Settings;

 /*
 {
                "sqNum" : isCorrect,
                "isCorrect" : isCorrect,
                "excerciseId" : "ex",
                "excerciseCategory" : questionType,
                "totalAttempted" : totalAttempted,
                "question_text" : questionString,
                "userAnswer" : userAnswer
              }
 
 */ 