import React, { useState } from 'react';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import TemporaryDrawer from "./appDrawer"
import SimpleTest from "./dummy"
import Avatar from '@material-ui/core/Avatar';
import { amber, deepPurple } from '@material-ui/core/colors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { removeItemValue,getLocalValue, setLocalValue,logoutProcessing } from '../lib/utils';

var isLoaded = false;
const ButtonAppBar = ({  onChangeOperation }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [appBarLabel, setAppBarLabel] = useState(
    'Addition'
  );
  
  function goToLoginScreen(){
    window.location=process.env.AWS_LOGIN_URL;
  }

  function handleTitleChange(value) {
      setAppBarLabel(value);
      onChangeOperation(value);
  }

  var avatarTxt = "";
  function hasLoggedIn(){

    if(typeof localStorage === 'undefined'){
        return false;
    }
    if(localStorage.getItem('username')!="null" && localStorage.getItem('username')!=null ){
      var name = localStorage.getItem('username').split(" ");
      avatarTxt = (name[0][0] + (name.length > 1 ? name[name.length-1][0] : name[0][1]) ).toUpperCase();
      return  (
                localStorage.getItem('username') !== "" ? true : false
              );
    }
    else{
      return false;
    }

  } 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    if(event.target.value==0){
      /*removeItemValue("sid");
      removeItemValue("username");
      removeItemValue("userid");
      if(window){
         window.location = process.env.AWS_LOGOUT_URL;
      }*/
      logoutProcessing();
    }
  };
  if(!isLoaded){
    isLoaded=true;
    try{
      if(getLocalValue("sid")==null){
        var urlPortions = (""+window.location).split("#");
        if(urlPortions.length > 1){
          var userDetails = JSON.parse(atob(urlPortions[1]));
          console.log(userDetails);
          localStorage.setItem('sid', userDetails["accessToken"]);
          localStorage.setItem('userid', userDetails["userId"]);
          localStorage.setItem('username', userDetails["username"]);
          localStorage.setItem('refresh_token', userDetails["refresh_token"]);  
          window.location = urlPortions[0];
        }
      }
    }catch(err){}
}

const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <TemporaryDrawer onChangeHeadline={handleTitleChange}/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            The Niral
          </Typography>
          {
              hasLoggedIn()? 
              <Avatar className={classes.orange} style={{cursor:"pointer"}}
              aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}
              >{avatarTxt}</Avatar>
              : 
              <Button color="inherit" onClick={goToLoginScreen}>Login</Button>           
          }
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={(event) => handleClose(event) }>Logout</MenuItem>
            </Menu>

        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ButtonAppBar;
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    title: {
      flexGrow: 1,
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    orange: {
      color: theme.palette.getContrastText(amber[700]),
      backgroundColor: amber[700],
    }    
}));







  
