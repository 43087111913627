import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PageHeader from '../components/templateHeader';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import Canvas from '../components/canvas';
import Button from '@material-ui/core/Button';

import Multiplication from "../components/multiplication"
import ExamProgress from "../components/sheetProgressTracker"
import MyPerformance from "../components/myPerformance"
import MySetting from "../components/mySettings"

import { getLocalValue, setLocalValue } from '../lib/utils';

setLocalValue(
            "questionsPerExcercise",
            parseInt( getLocalValue("questionsPerExcercise")==null? "25" : getLocalValue("questionsPerExcercise") )
      );
var d = new Date();
var n = d.getMilliseconds();
const tileData = [
     {
       "img": "image",
       "title": "Image",
       "author": "author",
       "cols": 2,
     }
   ];
export default function Index() {
  const classes = useStyles();

const [state, setState] = React.useState(
  {
    "appBarLabel":getLocalValue("lastExcerciseName")=="" || getLocalValue("lastExcerciseName")==null ? 'Addition':getLocalValue("lastExcerciseName"),
    "questionsPerExcercise" : getLocalValue("questionsPerExcercise")
}
);


const [appBarLabel, setAppBarLabel] = React.useState(state.appBarLabel);
const [questionsPerExcercise, setQuestionsPerExcercise] = React.useState(getLocalValue("questionsPerExcercise"));

const [accessToken, setAccessToken] = React.useState(getLocalValue("sid"));


function handleCurrentSelectedOperation(value) {
  console.log("start handle----"+value+"----"+state.appBarLabel);
  if(typeof value === "undefined" || value===null || value===""){
    state.appBarLabel = (getLocalValue("lastExcerciseName")==="" || getLocalValue("lastExcerciseName")==null 
    ?'Addition':getLocalValue("lastExcerciseName"));
  }
  else{
    state.appBarLabel = value;
  }
  setAppBarLabel(state.appBarLabel);
  console.log("finshed handle----"+value+"----"+state.appBarLabel);
}
function handleReflectNumberOfQuestionsPerSheetInApp(value){
    state.questionsPerExcercise = value;
    setQuestionsPerExcercise(value);
}
const [nextAnswer, setNextAnswer] = React.useState({"isCorrect":"false"});  

function handleAnswerOperation(value){
  if(typeof value !== 'undefined'){
    state["nextAnswer"]=value;
    setNextAnswer(value);
  }
}

if(!state.appBarLabel.startsWith("other")){
    return (
        <Container className={classes.root}>
          <PageHeader onChangeOperation={handleCurrentSelectedOperation}/>

                <Grid style={{marginTop: "0"}} container spacing={3} m={10}>
                  <Grid item xs={12} sm={6} lg={6} >
                        <Typography variant="h4" component="h1" >
                          <Multiplication questionType={appBarLabel} onAnswerOperation={handleAnswerOperation} questionsPerExcercise={questionsPerExcercise} />
                        </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6} >
                          <ExamProgress addNext={state["nextAnswer"]} excerciseCategory={appBarLabel} questionsPerExcercise={questionsPerExcercise} />
                  </Grid>
                </Grid>
        </Container>
      );
}
else if(state.appBarLabel === "other:mycharts"){
  return(
        <Container className={classes.root}>
          <PageHeader onChangeOperation={handleCurrentSelectedOperation}/>
            <MyPerformance actionNumber={((new Date()).getTime())} onChangeReflectInApp={handleReflectNumberOfQuestionsPerSheetInApp} />
        </Container>
);
}
else if(state.appBarLabel === "other:setting" ){
  return(
    <Container className={classes.root}>
      <PageHeader onChangeOperation={handleCurrentSelectedOperation}/>
        <MySetting onChangeReflectInApp={handleReflectNumberOfQuestionsPerSheetInApp} />
    </Container>
);
}
else{
  return(
    <Container className={classes.root}>
      <PageHeader onChangeOperation={handleCurrentSelectedOperation}/>
        <h2>other page</h2>
    </Container>
);
}



}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
