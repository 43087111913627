import React from 'react';
import ReactDOM from 'react-dom';
import CardHeader from '@material-ui/core/CardHeader';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PageHeader from '../components/templateHeader';
import { makeStyles } from '@material-ui/core/styles';

import Canvas from '../components/canvas';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { Alert, AlertTitle } from '@material-ui/lab'; 
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AvTimerTwoToneIcon from '@material-ui/icons/AvTimerTwoTone';
import Popover from '@material-ui/core/Popover';
import { getLocalValue, setLocalValue } from '../lib/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      typography: {
        padding: theme.spacing(2),
      },
    orange: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
    },
    purple: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
    },
  }));
  var previousExcerciseCategory = "";
  var previousSqNum="";
  var defaultNumberOfQuestionsPerSheet = parseInt( getLocalValue("questionsPerExcercise")==null? "25" : getLocalValue("questionsPerExcercise") );
  function saveSheetProgress(excerciseCategory,soFarProgress){
    if(typeof localStorage !== 'undefined'){
        localStorage.setItem('excerciseCategory'+excerciseCategory, soFarProgress);
    }
  }
  function getSaveSheetProgress (excerciseCategory){
    if(typeof localStorage !== 'undefined'){
        var storedValue = localStorage.getItem('excerciseCategory'+excerciseCategory);
        if(storedValue==="" || storedValue=== null || typeof storedValue === 'undefined'){
             return [];   
        }   
        else{
            return JSON.parse(storedValue);
        }
    }
    return [];
    }
 const SheetProgress = ({  addNext, excerciseCategory, questionsPerExcercise }) => {

    const [trackerMap, setTrackerMap] = React.useState('');  
    const [state, setState] = React.useState({
            "trackerMap":  getSaveSheetProgress(excerciseCategory),
            "maxQuestions" : questionsPerExcercise
     }); 

    console.log("state.trackerMap");
    const classes = useStyles();
    console.log("add next"); 
    console.log(addNext);
    console.log(0);
    if(typeof addNext !== 'undefined' && addNext !== "undefined"){
        console.log(1);
       if(addNext.sqNum===previousSqNum){
            state.trackerMap=[];
       }
       if(addNext["isRestarted"]==="true"){
        console.log(2);
            state.trackerMap = []; 
            saveSheetProgress(addNext.excerciseCategory,JSON.stringify(state.trackerMap))
       }
       else if(addNext.sqNum!==previousSqNum){ 
        console.log(3);
            previousExcerciseCategory = addNext.excerciseCategory;
            previousSqNum = addNext.sqNum;
            state.trackerMap[state.trackerMap.length]=addNext;
            saveSheetProgress(addNext.excerciseCategory,JSON.stringify(state.trackerMap))
       }

    }
    else{
        state.trackerMap=[];
    }

    if(getSaveSheetProgress(excerciseCategory).length>0){
        state.trackerMap= getSaveSheetProgress(excerciseCategory);
    }
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Container className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12} >
                        <span style={{display: "inline-flex",verticalalign: "middle"}}> <AvTimerTwoToneIcon/> Progress</span>
                    <Divider />
                </Grid>
                {state.trackerMap.map((val, index) => (
                    <Grid item key={index}>
                        <Avatar 
                        aria-describedby={id} variant="contained" color="primary" onClick={handleClick}
                        className={state.trackerMap[index].isCorrect ? classes.purple : classes.orange} variant="square"
                        style={{pointer:"cursor"}}>
                        {(index+1)}
                        </Avatar>
                    </Grid>
                ))}
                {Array.apply(null, { length: state.maxQuestions - state.trackerMap.length }).map((e, i) => (
                        <Grid item >
                            <Avatar variant="square">{state.trackerMap.length+i+1}</Avatar>
                        </Grid>
                ))}
            </Grid>
            <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
            >
        <Typography className={classes.typography}>The content of the Popover.</Typography>
      </Popover>
    </Container>
    
    );

}

export default SheetProgress;

 /*
 {
                "sqNum" : isCorrect,
                "isCorrect" : isCorrect,
                "excerciseId" : "ex",
                "excerciseCategory" : questionType,
                "totalAttempted" : totalAttempted,
                "question_text" : questionString,
                "userAnswer" : userAnswer
              }
 
 */ 