import React from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { getLocalValue, setLocalValue,sliceArray,getSortOrder,getWeekOfTheYear,isNotUndefined,logoutProcessing,retriveInformation } from '../lib/utils';
import { Bar } from 'react-chartjs-2';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Divider from '@material-ui/core/Divider';
import ChatBubbleOutlineSharpIcon from '@material-ui/icons/ChatBubbleOutlineSharp';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      typography: {
        padding: theme.spacing(2),
      },
    orange: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
    },
    purple: {
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
    },
}));

  var defaultValue = 25;
  try{
            defaultValue = parseInt( getLocalValue("questionsPerExcercise")==null? "25" : getLocalValue("questionsPerExcercise") );
  }catch(err){
      alert(err);
  }
  var lastRefreshNumber = 0;    
  var tempChartData = null;

  const MyPerformance = ({  actionNumber, onChangeReflectInApp }) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
            "trackerMap":  "",
            "isLoaded" : "false",
            "sheetsPayload" : [],
            "barData" : {
              labels: [],
              datasets: [
                {
                  label: 'Weekly excercises completion',
                  borderColor: 'rgba(0,0,0,1)',
                  borderWidth: 1,
                  data: []
                }
              ]
            }
     }); 
     const [trackerMap, setTrackerMap] = React.useState('');  
     const [isLoaded, setIsLoaded] = React.useState('');
     const [sheetsPayload, setSheetsPayload] = React.useState('');
     const [barData, setBarData] = React.useState('');

    function processMyPerformanceResponse(responseAsJson) {
      console.log(responseAsJson);
      if(isNotUndefined(responseAsJson) && isNotUndefined(responseAsJson.data)){
        responseAsJson.data.sort(getSortOrder("last_modified_datetime"))
        var responseData= [];
        for(var lp=0;lp<responseAsJson.data.length;lp++){
          if(responseAsJson.data[lp]["finish_datetime"])
          responseData.push(responseAsJson.data[lp]);
        }
        state.sheetsPayload = responseData.reverse();
        setSheetsPayload(state.sheetsPayload);
        prepareChartData();
      }
      else{
        state.isLoaded = "true";
        setIsLoaded("true");
      }
      console.log(state.sheetsPayload);
    }
    function retirveSheets(){
      retriveInformation(
                    process.env.AWS_POST_ANSWERS,
                    "POST",{},
                    {"actionName" : "querySheets"},
                    processMyPerformanceResponse
                );
    }
    const handleClick = (event) => {
    };
    function valuetext(value) {
        return value;
    }
    function goToLoginScreen(){
      window.location=process.env.AWS_LOGIN_URL;
    }
  
  function prepareChartData(){
      if(tempChartData!=null){
        return tempChartData;
      }
      var labels = [];
      var data = [];
      for(var lp=30;lp<=52;lp++){
        labels.push("Week " + lp);
        data.push(0);
      }
      for(var lp=0;lp<state.sheetsPayload.length;lp++){
        if(state.sheetsPayload[lp]["finish_datetime"]){
          var dateParts = state.sheetsPayload[lp]["finish_datetime"].split(" ")[0].split("-");
          //var timeParts = state.sheetsPayload[lp]["last_modified_datetime"].split(" ")[0].split("-");
          var cDate = new Date(dateParts[0], dateParts[1], dateParts[2], 0, 0, 0, 0);
          console.log(cDate);
          data[getWeekOfTheYear(cDate)-31] = data[getWeekOfTheYear(cDate)-31] + 1;
        }
      }
      state.barData.labels = labels;
      state.barData.datasets[0].data = data;
      setBarData(state.barData); 
      return tempChartData;
    }



    //alert(lastRefreshNumber+"----"+actionNumber);
    if(getLocalValue('sid')!=null && typeof lastRefreshNumber!=="undefined" && lastRefreshNumber!==actionNumber){
      retirveSheets();
      console.log(state.sheetsPayload);
      lastRefreshNumber=actionNumber;
    }
    if(getLocalValue('sid')==null){
      lastRefreshNumber=actionNumber;
    }
    return (
                <Container>
                  <h2>My Past Performances</h2>
                  {
                    getLocalValue('sid') ==null?
                    <Alert severity="info" style={{marginTop: "20px"}}>
                      <AlertTitle>Login</AlertTitle>
                      Please <Button color="inherit" onClick={goToLoginScreen}>Login</Button>  to retrieve your performance.
                    </Alert>
                    :
                  <div>    
                  {
                    getLocalValue('sid') !=null && (typeof state.sheetsPayload!="undefined" && state.sheetsPayload.length===0) 
                    ?
                    <Timeline align="alternate">
                      <TimelineItem>
                        <TimelineSeparator>
                          <CircularProgress />
                        </TimelineSeparator>
                        <TimelineContent></TimelineContent>
                      </TimelineItem>
                    </Timeline>
                    :
                    <Grid style={{marginTop: "0"}} container spacing={3} m={10}>
                        <Grid item xs={12} sm={6} lg={6} >
                          <Paper style={{padding :"10px"}}>
                            <Bar
                              data={state.barData}
                              width={100}
                              height={50}
                            />
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} >
                        <Box>
                          <Timeline align="">
                                {state.sheetsPayload.map((answerSheet,index) => (
                                  <TimelineItem>
                                    <TimelineSeparator>
                                    <TimelineDot color="primary" />
                                    {index==state.sheetsPayload.length-1?"":<TimelineConnector />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper style={{padding :"10px"}}>
                                          <b>{state.sheetsPayload.length-index} . {answerSheet.title}</b> 
                                          <Divider/>
                                          Correct Answers - {answerSheet.total_correct}
                                          <br/> Total Questions - {answerSheet.maxQuestion}
                                          <br/> Finished - {answerSheet.finish_datetime}
                                          <Divider/>
                                          <Box style={{paddingTop :"10px"}}>
                                            <ChatBubbleOutlineSharpIcon fontSize="small" style={{verticalAlign:"middle"}}/> <i>{answerSheet.note}</i>
                                            </Box>
                                        </Paper>                                                  
                                    </TimelineContent>
                                </TimelineItem>
                          ))}
                          </Timeline>
                      </Box>
                      </Grid>  
                      
                  </Grid>

                } </div> }         
                </Container>
    );


}

export default MyPerformance;
