export function getLocalValue(keyName){
    if(typeof localStorage !== 'undefined'){
        return localStorage.getItem(keyName);
    }
    return "";
  }
  export function removeItemValue(keyName){
    if(typeof localStorage !== 'undefined'){
        return localStorage.removeItem(keyName);
    }
  }

  
export function setLocalValue(keyName,value){
    if(typeof localStorage !== 'undefined'){
        return localStorage.setItem(keyName,value);
    }
    return "";
  }
export function sliceArray(arrayName, sliceTo){
  try{
    return (arrayName.slice(0,sliceTo));
  }catch(err){
    alert(err);
  }
  return arrayName;
}  

export function getSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 
export function isNotUndefined(val){
  if(typeof val !== "undefined"){
    return true;
  }
  return false;
}
export function getWeekOfTheYear(inputDate) {
  var date = new Date(inputDate.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}

export function logoutProcessing(){
  removeItemValue("sid");
  removeItemValue("username");
  removeItemValue("userid");
  removeItemValue("refresh_token");
  if(window){
     window.location = process.env.AWS_LOGOUT_URL;
  }
}
export function retriveInformation(endpoint,reqMethod,reqHeaders,payload,callbackFunction){
     reqHeaders['Content-Type'] = 'application/json';
     reqHeaders['authorization'] = getLocalValue('sid');
     makeCallouts(endpoint,reqMethod,reqHeaders,payload,callbackFunction,true);
}
export function makeCallouts(endpoint,reqMethod,reqHeaders,payload,callbackFunction,doRetry){
    fetch(endpoint, {
      method: reqMethod,
      headers: reqHeaders,
      body: JSON.stringify(payload)
    }).then(function(response){
        //alert(2);
        if(response.status >= 400 && response.status <=499 ){
            if(doRetry){
              //alert(3);
              getAccessTokenUsingRefreshToken(endpoint,reqMethod,reqHeaders,payload,callbackFunction);
            }
            //alert(4);
            return null;
        }
        return response.json();
    }).then(function(responseAsJson) {
      callbackFunction(responseAsJson);
    })
    .catch(function(error) {
      console.log('Looks like there was a problem: \n', error);
    });
}

export function getAccessTokenUsingRefreshToken(endpoint,reqMethod,reqHeaders,payload,callbackFunction){
  //alert(5);
  fetch("https://api.theniral.com/v1/auth", {
    method: "POST",
    headers: {"Content-Type":"application/json","refresh_token_req":"yes"},
    body: JSON.stringify({"refresh_token":getLocalValue('refresh_token')})
  }).then(function(response) {
    //alert(6);
      console.log("here is the response");
      console.log(response.body );
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
  })
  .then(function(responseAsJson){
      //alert(7);
      setLocalValue("sid",responseAsJson.accessToken);
      reqHeaders.authorization = getLocalValue("sid");
      makeCallouts(endpoint,reqMethod,reqHeaders,payload,callbackFunction,false);
  })
  .catch(function(error) {
    console.log('Looks like there was a problem: \n', error);
  });
}