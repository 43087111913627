import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import TrendingUpSharpIcon from '@material-ui/icons/TrendingUpSharp';
import TuneSharpIcon from '@material-ui/icons/TuneSharp';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});
var selected
const Headline = ({  onChangeHeadline }) => {
  
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    selectedIdx : 0
  });
  const menuItems = ['Addition', 'Substraction', 'Multiplication', 'Division'];
  const otherMenuItems = ['Addition', 'Substraction', 'Multiplication', 'Division','other:mycharts','other:setting'];
  const menuItemsSymbols = ['+', '-', '×', '÷','',''];
  const [selectedIdx, setSelectedIdx] = useState(
    0
  );
  const callParent= (event,idx) =>{

    if(idx < menuItems.length){
        onChangeHeadline(menuItems[idx]);
        setSelectedIdx(idx);
    }
    else{
      onChangeHeadline(otherMenuItems[idx]);
      setSelectedIdx(idx);
    }
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    //setGreeting("Addition");
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {
            menuItems.map((text, index) => (
                <ListItem selected={index==selectedIdx?true:false} button key={text} onClick={(event) => callParent(event, index)}>
                  <ListItemIcon><ArrowRightSharpIcon color="secondary"/></ListItemIcon>
                  <ListItemText color="secondary" primary={menuItemsSymbols[index]+" "+text}/><Divider /> 
                </ListItem>
            ))
        }
        <Divider />
        <ListItem  selected={4==selectedIdx?true:false} button key="My Performance" onClick={(event) => callParent(event,4)}>
            <ListItemIcon><TrendingUpSharpIcon color="secondary"/></ListItemIcon>
            <ListItemText primary="My Performance"/>
        </ListItem>
        <Divider />
        <ListItem  selected={5==selectedIdx?true:false} button key="Settings" onClick={(event) => callParent(event,5)}>
            <ListItemIcon><TuneSharpIcon color="secondary"/></ListItemIcon>
            <ListItemText primary="Setting"/>
        </ListItem>

      </List>
    </div>
  );
  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <MenuIcon aria-controls="simple-menu" aria-haspopup="true" onClick={toggleDrawer('left', true)}/>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );

};
export default Headline;
